import * as web3 from '@solana/web3.js';

export const BURNER_WALLET_PK = process.env
  .REACT_APP_BURNER_WALLET_PK as string;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL as string;

const METADATA_PREFIX = 'metadata';
const METADATA_PROGRAM = 'metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s';
const metaProgramPublicKey = new web3.PublicKey(METADATA_PROGRAM);
const metaProgramPublicKeyBuffer = metaProgramPublicKey.toBuffer();
const metaProgramPrefixBuffer = Buffer.from(METADATA_PREFIX);
