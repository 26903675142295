import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

import { web3 } from '../blockchain/web3Utils';

export const connectedWalletState = atom({
  key: 'connectedWallet', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

const useEthereumWeb3 = () => {
  // @ts-ignore
  const ethereum = window.ethereum;

  // @ts-ignore
  const isMetaMaskAvailable = typeof ethereum !== 'undefined';

  const [connectedWallet, setConnectedWallet] =
    useRecoilState(connectedWalletState);

  const requestConnectWallet = useCallback(async () => {
    const accounts = await ethereum.request({
      method: 'eth_requestAccounts',
    });
    const accountAddress: string = accounts[0];
    setConnectedWallet(accountAddress);

    return accountAddress;
  }, [ethereum, setConnectedWallet]);

  return {
    isMetaMaskAvailable,
    ethereum,
    web3,
    requestConnectWallet,
    connectedWallet,
  };
};

export default useEthereumWeb3;
