import { useCallback, useState } from 'react';
import useAuthentication from '../hooks/useAuthentication';
import { logErrorEvent } from '../services/AnalyticsService';
import useEthereumWeb3 from './useEthereumWeb3';

const useRedemption = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { ethereum, connectedWallet } = useEthereumWeb3();

  const signMessage = useCallback(
    async (message) => {
      const signedMessage = await ethereum.request({
        method: 'personal_sign',
        params: [message, connectedWallet, ''],
      });
      return signedMessage;
    },
    [ethereum, connectedWallet]
  );

  const { authenticate } = useAuthentication({
    signMessage: signMessage,
  });

  const redeemNFT = useCallback(
    async (data, drop) => {
      const [messageId, signature] = (await authenticate(
        connectedWallet
      )) as string[];
      setIsLoading(true);

      // This is required for correctly indexing orders in Shopify
      // because Shopify is throwing an ErrorCode 422 for non-US phone
      // numbers which do not have a '+'. Shopify is okay with US phone numbers
      // having no '+' at the begining.
      // eslint-disable-next-line eqeqeq
      if (data.country != 'US' && !data.phone.startsWith('+')) {
        data.phone = '+' + data.phone;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/redeem/${drop}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Basic ${btoa(`${messageId}:${signature}`)}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            shippingAddress: {
              address1: data.address1,
              address2: data.address2,
              city: data.city,
              zip: data.zip,
              country: data.country,
              province: data.province,
            },
            lineItems: [
              {
                name: data.lineItems[0].name,
                quantity: data.lineItems[0].quantity,
                price: data.lineItems[0].price,
                nftName: data.lineItems[0].nftName,
                nftMint: data.lineItems[0].nftMint,
                size: data.shoeSize,
                properties: data.lineItems[0].properties,
              },
            ],
          }),
        }
      );
      const resJSON = await response.json();

      if (response.status > 399) {
        setIsLoading(false);
        logErrorEvent({
          action: 'redeem_nft',
          message: `Could not redeem for ${data.lineItems[0].nftName} | ${data.lineItems[0].nftMint} | ${data.shoeSize}`,
        });
        if (
          resJSON.statusCode === 500 &&
          resJSON.message === 'Request failed with status code 422'
        ) {
          throw new Error(
            'There seems to be a mistake with your contact information. Please be sure that everything you entered is correct.'
          );
        } else {
          throw new Error(
            'Something went wrong during the redemption of your NFT.'
          );
        }
      } else {
        setIsLoading(false);
        return resJSON;
      }
    },
    [authenticate, connectedWallet]
  );

  return { redeemNFT, isLoading };
};

export default useRedemption;
