import { useCallback } from 'react';

const useAuthentication = (props: {
  signMessage: (message: string) => any;
}) => {
  const { signMessage } = props;

  async function callAuthApi(publicKey: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth`, {
      method: 'POST',
      body: JSON.stringify({ publicKey }),
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.status > 399) {
      throw new Error('Authentication request failed');
    }

    return (await response.json()) as {
      message: string;
      messageId: string;
    };
  }

  const authenticate = useCallback(
    async (publicKey) => {
      const { message, messageId } = await callAuthApi(publicKey);

      const signature = await signMessage(message);

      return [messageId, signature];
    },
    [signMessage]
  );

  return { authenticate };
};

export default useAuthentication;
