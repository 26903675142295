const logEvent = async (eventName: string, params: any): Promise<any> => {
  // @ts-ignore
  window.gtag('event', eventName, params);
};

const logErrorEvent = (params: any) => {
  logEvent('error', params);
};

const setUserProperties = (props: any) => {
  // @ts-ignore
  window.gtag('set', 'user_properties', props);
};

export { logErrorEvent, logEvent, setUserProperties };
