import { web3 } from './web3Utils';

const contract = require('@truffle/contract');
const Drop2JSON = require('./DropTwo.json');

// Mainnet: 0x140197fbb6119f17311f414c367d238d181d085d
// Rinkbey: 0x6A67c6C07190DeF80A1c9544f4aE96b01b0251De
const drop2ContractAddresses: { [key: string]: any } = {
  development: '',
  staging: '0x6A67c6C07190DeF80A1c9544f4aE96b01b0251De',
  production: '0x140197fbb6119f17311f414c367d238d181d085d',
};
const getDrop2ContractAddress = () => {
  return drop2ContractAddresses[
    process.env.REACT_APP_NODE_ENV || 'development'
  ];
};

export const initDrop2 = async () => {
  var EndstateNft = contract(Drop2JSON);
  EndstateNft.setProvider(web3.currentProvider);
  const drop2ContractAddress = getDrop2ContractAddress();

  return await EndstateNft.at(drop2ContractAddress);
};
