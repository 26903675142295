import React from 'react';
import ReactDOM from 'react-dom';
// import TagManager from 'react-gtm-module';

import AppWrapper from './AppWrapper';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

require('dotenv').config();

ReactGA.initialize(process.env.REACT_APP_G_TAG || '');
ReactGA.pageview(window.location.pathname + window.location.search);

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_ID || 'NOT_FOUND',
// };

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
