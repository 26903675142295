import { useWallet } from '@solana/wallet-adapter-react';
import React, {
  createContext,
  ReactChild,
  useCallback,
  useContext,
} from 'react';

import { API_BASE_URL } from '../constants';

async function callAuthApi(publicKey: string) {
  const response = await fetch(`${API_BASE_URL}/auth`, {
    method: 'POST',
    body: JSON.stringify({ publicKey }),
    headers: { 'Content-Type': 'application/json' },
  });

  if (response.status > 399) {
    throw new Error('Authentication request failed');
  }

  return (await response.json()) as {
    message: string;
    messageId: string;
  };
}

type ContextType = () => Promise<string[]>;

export const AuthClientContext = createContext<ContextType>(() =>
  Promise.resolve([])
);

function AuthClientProvider(props: { children: ReactChild }) {
  const { children } = props;

  const wallet = useWallet();

  const authenticate = useCallback(async () => {
    const userPk = wallet.publicKey?.toBase58();
    if (!userPk) {
      throw new Error('Wallet not connected');
    }

    const { message, messageId } = await callAuthApi(userPk);

    const utf8Encode = new TextEncoder();
    const bytes = utf8Encode.encode(message);

    if (!wallet.signMessage) {
      throw new Error('Wallet does not support user signature request');
    }

    const signature = await wallet.signMessage(bytes);

    return [messageId, signature.join(',')];
  }, [wallet]);

  return (
    <AuthClientContext.Provider value={authenticate}>
      {children}
    </AuthClientContext.Provider>
  );
}

export const useAuthentication = () => {
  return useContext(AuthClientContext);
};

export default AuthClientProvider;
