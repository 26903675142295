import "./Header.scss";

import React from "react";

import logo from "../../assets/images/endstate-logo.png";

const Header: React.FC<any> = () => {
  return (
    <div className="header">
      {/* left */}
      <div className="section left">
        <a href="https://www.endstate.io/">
          <img className="logo" src={logo} alt="Endstate" />
        </a>
      </div>
      {/* center */}
      <div className="section center"></div>
      {/* right */}
      <div className="section right">
        <a className="link" href="https://www.endstate.io/drops/drop-0">
          DROPS
        </a>
        <a className="link" href="https://discord.gg/2bHG8uukxV">
          COMMUNITY
        </a>
        <a className="link" href="https://www.endstate.io/about">
          ABOUT
        </a>
      </div>
    </div>
  );
};

export default Header;
