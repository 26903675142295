import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-phone-input-2/lib/material.css';
import './RedeemForm.scss';

import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Button as BootstrapButton } from 'react-bootstrap';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import React, { useCallback, useState } from 'react';
import { CountryRegionData } from 'react-country-region-selector';
import { TailSpin } from 'react-loader-spinner';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';

import CloseIcon from '../assets/icons/cross.png';
import EditIcon from '../assets/icons/edit.png';
import { ShoeSizeChart } from './ShoeSizeChart';
import { NFTData } from '../types/NFTData';
import { logErrorEvent, logEvent } from '../services/AnalyticsService';

let Joi = require('joi');
Joi = Joi.extend(require('joi-phone-number'));

interface RedeemFormParams {
  nft: NFTData;
  redeemNft: (
    data: IRedeemDataProps
  ) => Promise<{ transactionHash: string; gasUsed: number }>;
  onClose: (success: boolean) => void;
  logParams: any | undefined;
}

interface IRedeemData {
  [Key: string]: string;
}

interface ILineItemsProps {
  name: string;
  quantity: number;
  price: number;
  nftMint: string; // For Solana, this is the token address, ex dfn3...29vs. For Eth, this is the id of the token, ex 3
  nftName?: string; // For Ethereum, this is the display name that makes all NFTs unique. ex: Blitkick #3. For Sol, this can be blank
  properties?: { value: string; name: string }[];
}
export interface IRedeemDataProps {
  shoeSize: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  country: string;
  province: string;
  lineItems?: ILineItemsProps[];
  [propName: string]: string | ILineItemsProps[] | undefined;
}

interface ErrorState {
  shoeSize?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  address1?: string;
  city?: string;
  zip?: string;
  country?: string;
  province?: string;
}

const shoeSizes = [
  { label: 'US M 3.5', value: 'US M 3.5' },
  { label: 'US M 4', value: 'US M 4' },
  { label: 'US M 4.5', value: 'US M 4.5' },
  { label: 'US M 5', value: 'US M 5' },
  { label: 'US M 5.5', value: 'US M 5.5' },
  { label: 'US M 6', value: 'US M 6' },
  { label: 'US M 6.5', value: 'US M 6.5' },
  { label: 'US M 7', value: 'US M 7' },
  { label: 'US M 7.5', value: 'US M 7.5' },
  { label: 'US M 8', value: 'US M 8' },
  { label: 'US M 8.5', value: 'US M 8.5' },
  { label: 'US M 9', value: 'US M 9' },
  { label: 'US M 9.5', value: 'US M 9.5' },
  { label: 'US M 10', value: 'US M 10' },
  { label: 'US M 10.5', value: 'US M 10.5' },
  { label: 'US M 11', value: 'US M 11' },
  { label: 'US M 11.5', value: 'US M 11.5' },
  { label: 'US M 12', value: 'US M 12' },
  { label: 'US M 12.5', value: 'US M 12.5' },
  { label: 'US M 13', value: 'US M 13' },
  { label: 'US M 13.5', value: 'US M 13.5' },
  { label: 'US M 14', value: 'US M 14' },
  { label: 'US M 15', value: 'US M 15' },
  { label: 'US M 16', value: 'US M 16' },
];

export const RedeemForm: React.FC<RedeemFormParams> = ({
  nft,
  redeemNft,
  onClose,
  logParams,
}) => {
  const [redeemData, setRedeemData] = useState<IRedeemDataProps>({
    shoeSize: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    country: 'US',
    province: '',
    lineItems: [
      {
        name: nft?.shoeName,
        quantity: 1,
        price: 0,
        nftMint: nft?.nftMint,
        nftName: nft?.dropName,
      },
    ],
  });
  const [shoeLabel, setShoeLabel] = useState('');
  const [errorMessage, setErrorMessage] = useState<ErrorState>({
    shoeSize: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    city: '',
    zip: '',
    country: '',
    province: '',
  });
  const [formValid, toggleFormValid] = useState(false);
  const [sizeChartOpen, toggleSizeChartOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState('');
  // const [transactionHash, setTransactionHash] = useState('');

  countries.registerLocale(enLocale);
  const countryObj = countries.getNames('en', { select: 'official' });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });

  const validateInput = useCallback(
    (name: string, value: string | ILineItemsProps[] | number | undefined) => {
      const validation: IRedeemData = {};
      switch (name) {
        case 'shoeSize':
          validation.shoeSize = Joi.string()
            .required()
            .label('Shoe Size')
            .messages({
              'string.empty': 'Please enter a valid shoe size',
            });
          break;
        case 'firstName':
          validation.firstName = Joi.string()
            .required()
            .max(24)
            .label('First Name')
            .messages({
              'string.empty': 'Please enter a valid first name',
              'string.max': 'Please enter a valid first name',
            });
          break;
        case 'lastName':
          validation.lastName = Joi.string()
            .required()
            .max(24)
            .label('Last Name')
            .messages({
              'string.empty': 'Please enter a valid last name',
              'string.max': 'Please enter a valid last name',
            });
          break;
        case 'email':
          validation.email = Joi.string()
            .email({ tlds: { allow: false } })
            .required()
            .min(3)
            .max(64)
            .label('Email')
            .messages({
              'string.empty': 'Please enter a valid email address',
              'string.min': 'Please enter a valid email address',
              'string.max': 'Please enter a valid email address',
              'string.email': 'Please enter a valid email address',
            });
          break;
        case 'phone':
          // TODO: not sure how to get at this country value for the phone number
          validation.phone = Joi.string()
            .required()
            .phoneNumber()
            .label('Phone Number')
            .messages({
              'string.empty': 'Please enter a valid phone number',
              'phoneNumber.invalid': 'Please enter a valid phone number',
            });
          break;
        case 'address1':
          validation.address1 = Joi.string()
            .required()
            .min(3)
            .max(128)
            .label('Address 1')
            .messages({
              'string.empty': 'Please enter a valid address 1',
              'string.min': 'Please enter a valid address 1',
              'string.max': 'Please enter a valid address 1',
            });
          break;
        case 'address2':
          validation.address2 = Joi.string()
            .min(0)
            .max(128)
            .label('Address 2')
            .messages({
              'string.min': 'Please enter a valid address 2',
              'string.max': 'Please enter a valid address 2',
            });
          break;
        case 'city':
          validation.city = Joi.string()
            .required()
            .max(254)
            .label('City')
            .messages({
              'string.empty': 'Please enter a valid city',
              'string.max': 'Please enter a valid city',
            });
          break;
        case 'zip':
          if (redeemData.country === 'US') {
            validation.zip = Joi.string()
              .required()
              .min(5)
              .max(5)
              .label('Zip Code')
              .messages({
                'string.empty': 'Please enter a valid zip code',
                'string.min': 'Please enter a valid zip code',
                'string.max': 'Please enter a valid zip code',
              });
          } else {
            validation.zip = Joi.string()
              .required()
              .max(254)
              .label('Postal Code')
              .messages({
                'string.empty': 'Please enter a valid postal code',
                'string.max': 'Please enter a valid postal code',
              });
          }
          break;
        case 'country':
          validation.country = Joi.string()
            .required()
            .max(254)
            .label('Country')
            .messages({
              'string.empty': 'Please enter a valid country',
              'string.max': 'Please enter a valid country',
            });
          break;
        case 'province':
          if (redeemData.country === 'US') {
            validation.province = Joi.string()
              .required()
              .max(254)
              .label('State')
              .messages({
                'string.empty': 'Please enter a valid state',
                'string.max': 'Please enter a valid state',
              });
          } else {
            validation.province = Joi.string()
              .max(254)
              .label('State/Province')
              .messages({
                'string.max': 'Please enter a valid state/province',
              });
          }
          break;
      }

      const objToValidate = { [name]: value };
      const schema = Joi.object(validation);
      let { error } = schema.validate(objToValidate);
      return error ? error.message : '';
    },
    [redeemData]
  );

  const handleOnBlur = useCallback(
    async (e: any) => {
      const { name, value } = e.target;
      let error = await validateInput(name, value);
      setErrorMessage({
        [name]: error ? error : '',
      });
    },
    [validateInput]
  );

  const handleInputChange = useCallback(
    (e: any, redeemData: IRedeemDataProps) => {
      const { name, value } = e.target;
      const update = {
        ...redeemData,
        [name]: value,
      };
      if (name === 'shoeSize') {
        handleOnBlur(e);
      }
      // clear out address fields on country change
      if (name === 'country') {
        update.city = '';
        update.province = '';
        update.zip = '';
        setErrorMessage({
          city: '',
          province: '',
          zip: '',
        });
      }
      setRedeemData(update);
    },
    [handleOnBlur]
  );

  const validateForm = async (e: any) => {
    e.preventDefault();
    let isValid = true;

    const objToValidate = { ...redeemData };
    delete objToValidate.lineItems;

    let errorObject: any = {};

    for (let prop in objToValidate) {
      let result = validateInput(prop, objToValidate[prop]);
      if (result) {
        isValid = false;
        errorObject[prop] = validateInput(prop, objToValidate[prop]);
      }
    }

    if (isValid) {
      setIsLoading(true);
      try {
        await redeemNft(redeemData);
        setShowSuccess(true);
        logEvent('redemption', {
          ...logParams,
        });
        // if (redeemResult) {

        //   setTransactionHash(redeemResult.transactionHash);

        // } else {
        //   throw Error('Failed to redeem');
        // }
      } catch (e: any) {
        console.error(e);
        logErrorEvent({ action: 'redemption', message: e.toString() });
        setError(
          'Something went wrong with redemption. Check your details and try again.'
        );
      }
      setIsLoading(false);
    } else {
      setErrorMessage(errorObject);
    }
  };

  const renderUSRegionSelect = (isLoading: boolean) => {
    let countryData = CountryRegionData.filter(
      (country) => country[1] === 'US'
    )[0];
    let usStates = countryData[2].split('|');
    return (
      <FormControl fullWidth error={errorMessage?.province ? true : false}>
        <InputLabel id="province-label">State</InputLabel>
        <Select
          name="province"
          label={'State'}
          type="text"
          onBlur={handleOnBlur}
          value={redeemData.province}
          onChange={(e: any) => handleInputChange(e, redeemData)}
          disabled={isLoading}
        >
          {usStates?.map((usState) => {
            let stateName = usState.split('~')[0];
            return (
              <MenuItem key={usState} value={stateName}>
                {stateName}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{errorMessage?.province}</FormHelperText>
      </FormControl>
    );
  };

  const renderShoePicker = (isLoading: boolean) => {
    return redeemData.shoeSize ? (
      <OutlinedInput
        className="shoe-size-input"
        fullWidth
        disabled
        value={shoeLabel}
        sx={{ ml: 2, mt: 2 }}
        onClick={() => {
          if (!isLoading) {
            handleInputChange(
              { target: { name: 'shoeSize', value: '' } },
              redeemData
            );
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end">
              <img src={EditIcon} alt="Edit" width={20} height={20} />
            </IconButton>
          </InputAdornment>
        }
      />
    ) : (
      shoeSizes.map(({ label, value }) => {
        return (
          <Grid
            key={label}
            item
            onClick={() => {
              handleInputChange(
                { target: { name: 'shoeSize', value } },
                redeemData
              );
              setShoeLabel(label);
            }}
          >
            <div
              className="shoe-size-item"
              style={{
                border: 1,
                borderColor: `${
                  redeemData.shoeSize === value ? 'green' : 'black'
                }`,
              }}
            >
              {label}
            </div>
          </Grid>
        );
      })
    );
  };

  return (
    <dialog open={true} className="redeem-form-dialog">
      {!showSuccess ? (
        <>
          {!sizeChartOpen ? (
            <form
              className="redeem-nft-form"
              onSubmit={(e) => validateForm(e)}
              onReset={() => onClose(false)}
            >
              <IconButton
                type="reset"
                aria-label="close"
                style={{ marginLeft: 'calc(100% - 50px)' }}
              >
                <img width={30} height={30} src={CloseIcon} alt="Close" />
              </IconButton>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2 className="heading1">Redeem Your NFT</h2>
                </Grid>
                <Grid item xs={12}>
                  <h2 className="heading2">Sizing Information</h2>
                </Grid>
                <Grid item xs={12}>
                  Endstate sneakers fit true to size. You may find the toe area
                  slightly roomier than other brands. We suggest that you order
                  the size you typically wear in most athletic brands. For
                  women's sizing please refer to the Size Chart to select your
                  equivalent size in men's.
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  Given the finality of NFT transactions, all sales are final.
                  No exchanges will be made for fit purposes. For more on our
                  sales and return policy, see our{' '}
                  <a
                    href="https://endstate.io/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms
                  </a>
                  .
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="shoe-size-action size-chart-caption">
                    Select Size
                  </div>
                  <Link
                    className="shoe-size-action size-chart-btn"
                    to="#"
                    onClick={() => toggleSizeChartOpen(true)}
                  >
                    Size Chart &gt;
                  </Link>
                </Grid>
                {errorMessage?.shoeSize && (
                  <FormHelperText sx={{ ml: 2, mt: 2, color: 'red' }}>
                    {errorMessage?.shoeSize}
                  </FormHelperText>
                )}
                <Grid
                  container
                  justifyContent={`${redeemData.shoeSize ? 'left' : 'center'}`}
                  rowSpacing={2}
                  columnSpacing={2}
                  sx={{ margin: '0 auto' }}
                >
                  {renderShoePicker(isLoading)}
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <h2 className="heading2">Contact Details</h2>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="first-name-input"
                    name="firstName"
                    label="First Name"
                    type="text"
                    onBlur={handleOnBlur}
                    error={errorMessage?.firstName ? true : false}
                    helperText={errorMessage?.firstName}
                    value={redeemData.firstName}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="first-name-input"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    onBlur={handleOnBlur}
                    error={errorMessage?.lastName ? true : false}
                    helperText={errorMessage?.lastName}
                    value={redeemData.lastName}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="email-input"
                    name="email"
                    label="Email"
                    type="email"
                    onBlur={handleOnBlur}
                    error={errorMessage?.email ? true : false}
                    helperText={errorMessage?.email}
                    value={redeemData.email}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={errorMessage?.phone ? true : false}
                  >
                    <PhoneInput
                      country={'us'}
                      onBlur={(e) =>
                        handleOnBlur({
                          target: { name: 'phone', value: e.target.value },
                        })
                      }
                      defaultErrorMessage={errorMessage?.phone}
                      value={redeemData.phone}
                      onChange={(e) =>
                        handleInputChange(
                          { target: { name: 'phone', value: e } },
                          redeemData
                        )
                      }
                      disabled={isLoading}
                    />
                    <FormHelperText>{errorMessage?.phone}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <h2 className="heading2">Shipping address</h2>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">Country</InputLabel>
                    <Select
                      label="Country"
                      name="country"
                      onBlur={handleOnBlur}
                      value={redeemData.country}
                      onChange={(e: any) => handleInputChange(e, redeemData)}
                      disabled={isLoading}
                    >
                      {!!countryArr?.length &&
                        countryArr.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="city-input"
                    name="city"
                    label="City"
                    type="text"
                    onBlur={handleOnBlur}
                    error={errorMessage?.city ? true : false}
                    helperText={errorMessage?.city}
                    value={redeemData.city}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {redeemData?.country === 'US' ? (
                    renderUSRegionSelect(isLoading)
                  ) : (
                    <TextField
                      fullWidth
                      id="province-input"
                      name="province"
                      label="State/Province"
                      type="text"
                      onBlur={handleOnBlur}
                      error={errorMessage?.province ? true : false}
                      helperText={errorMessage?.province}
                      value={redeemData.province}
                      onChange={(e: any) => handleInputChange(e, redeemData)}
                      disabled={isLoading}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="zip-input"
                    name="zip"
                    label={redeemData?.country === 'US' ? 'Zip' : 'Postal code'}
                    type="text"
                    onBlur={handleOnBlur}
                    error={errorMessage?.zip ? true : false}
                    helperText={errorMessage?.zip}
                    value={redeemData.zip}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="address1-input"
                    name="address1"
                    label="Address 1"
                    type="text"
                    onBlur={handleOnBlur}
                    error={errorMessage?.address1 ? true : false}
                    helperText={errorMessage?.address1}
                    value={redeemData.address1}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="address1-input"
                    name="address2"
                    label="Address 2"
                    type="text"
                    onBlur={handleOnBlur}
                    value={redeemData.address2}
                    onChange={(e: any) => handleInputChange(e, redeemData)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div style={{ margin: '0 auto', width: 'fit-content' }}>
                    <Checkbox
                      onClick={() => toggleFormValid(!formValid)}
                      checked={formValid}
                      disabled={isLoading}
                    />
                    <span style={{ fontSize: '14px' }}>
                      I Agree to the{' '}
                      <a
                        href="https://endstate.io/terms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms &amp; Conditions
                      </a>{' '}
                      and&nbsp;
                      <a
                        href="https://endstate.io/privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </div>
                </Grid>
                {error.length > 0 && (
                  <Grid item xs={12}>
                    <p className="error-text">{error}</p>
                  </Grid>
                )}
                <Grid item xs={12} className="form-actions">
                  {isLoading && (
                    <div className="please-wait">
                      Please wait a moment. This will take at least 45 seconds.
                    </div>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    type="reset"
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading || !formValid}
                  >
                    {isLoading ? (
                      <TailSpin height={22} color="blue" />
                    ) : (
                      'Redeem NFT'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <ShoeSizeChart toggleSizeChartOpen={toggleSizeChartOpen} />
          )}
        </>
      ) : (
        <div className="success-container">
          <h2>Congratulations!</h2>
          <hr />
          <p>
            Your Endstate Drop 2: Statecraft sneakers have been successfully
            redeemed from your Drop 2 NFT.
          </p>
          <br />
          {/* <p>
            Click here{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://etherscan.io/tx/${transactionHash}`}
            >
              here
            </a>{' '}
            to view redemption transaction on Etherscan
          </p>
          <br /> */}
          <p>
            What’s next? Sit back while we manufacture your sneakers. Keep an
            eye out for emails from Endstate and check The State Department
            channel in the Endstate Discord for updates as your sneakers begin
            their journey to your doorstep.
          </p>
          <div className="mt-4 d-flex flex-row justify-content-center">
            <BootstrapButton
              variant="primary"
              className="mt-4"
              onClick={() => onClose(true)}
            >
              CLOSE
            </BootstrapButton>
          </div>
        </div>
      )}
    </dialog>
  );
};
