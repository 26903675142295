import './AppWrapper.scss';

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AuthProvider from './providers/AuthProvider';
import Drop2Reactor from './Pages/reactors/Drop2Reactor';
import { RecoilRoot } from 'recoil';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Drop0AllowListAdd from './Pages/allowlist/Drop0AllowListAdd';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');
require('./css/styles.css');

function AppWrapper() {
  const queryParams = new URLSearchParams(window.location.search);
  let redemptionOpen =
    queryParams.get('reactor') === 'online' ||
    queryParams.get('q') === 'X9r25acJjZPNWRNA' ||
    process.env.REACT_APP_REACTOR_ONLINE === 'true';

  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getTorusWallet({
        options: { clientId: 'Get a client ID @ https://developer.tor.us' },
      }),
      getLedgerWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [network]
  );

  const getContent = () => {
    if (!redemptionOpen) {
      return (
        <div className="redemption-closed">
          Redemption is closed
          <br />
          Stay tuned for the next State Change event
        </div>
      );
    }

    return (
      <Routes>
        <Route path="/" element={<Navigate to="/drop2" />} />
        <Route path="/drop2" element={<Drop2Reactor />} />
        <Route path="/drop2/drop0redeem" element={<Drop0AllowListAdd />} />
      </Routes>
    );
  };

  return (
    <div className="page">
      <RecoilRoot>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets}>
            <WalletModalProvider>
              <AuthProvider>
                <BrowserRouter>
                  <Header />
                  {getContent()}
                  <Footer />
                </BrowserRouter>
              </AuthProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </RecoilRoot>
    </div>
  );
}

export default AppWrapper;
