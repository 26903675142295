import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { ReactElement } from 'react';

const shoeSizeData = [
  {
    label: "US Men's",
    default: true,
    data: [
      3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5,
      12, 12.5, 13, 13.5, 14, 15, 16,
    ],
  },
  {
    label: "US Women's",
    data: [
      5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13,
      13.5, 14, 14.5, 15, 15.5, 16.5, 17.5,
    ],
  },
  {
    label: 'UK',
    data: [
      3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11,
      11.5, 12, 12.5, 13, 13.5, 14.5, 15.5,
    ],
  },
  {
    label: 'EU',
    data: [
      35, 36, 37, 37.5, 38, 38.5, 39.5, 40, 40.5, 41.5, 42, 42.5, 43, 44, 44.5,
      45, 45.5, 46.5, 47, 47.5, 48, 49, 50, 51,
    ],
  },
  {
    label: 'CM',
    data: [
      21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28,
      28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32, 33, 34,
    ],
  },
];

interface ShoeSizeChartProps {
  toggleSizeChartOpen: (isOpen: boolean) => void;
}

export const ShoeSizeChart: React.FC<ShoeSizeChartProps> = ({
  toggleSizeChartOpen,
}) => {
  const renderTableCells = () => {
    let htmlArray = [];
    for (let i = 0; i <= 24; i++) {
      htmlArray.push(
        <TableRow key={`shoe-size_row-${i}`}>
          {shoeSizeData.map((column) => {
            return (
              <TableCell
                className={column.default ? 'default-size' : undefined}
                align="center"
              >
                {column.data[i]}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
    return htmlArray;
  };

  return (
    <div
      style={{
        maxWidth: '1000px',
        margin: '0 auto',
        maxHeight: '90vh',
        backgroundColor: '#fff',
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: '0px 8px 20px rgb(0 0 0 / 60%)',
      }}
    >
      <div style={{ position: 'relative' }}>
        <button
          className="btn"
          style={{ position: 'absolute', left: '20px' }}
          onClick={() => toggleSizeChartOpen(false)}
        >
          Go Back
        </button>
        <h2 style={{ textAlign: 'center' }}>Shoe Size Chart</h2>
      </div>
      <TableContainer
        component={Paper}
        style={{ maxHeight: 'calc(100% - 70px' }}
      >
        <Table
          className="shoe-sizes-table"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {shoeSizeData.map((column) => {
                return (
                  <TableCell
                    className={column.default ? 'default-size' : undefined}
                    align="center"
                  >
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: 'auto' }}>
            {renderTableCells()}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
