import './Drop0AllowListAdd.scss';

import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { useAuthentication } from '../../providers/AuthProvider';

const Drop0AllowListAdd: React.FC = () => {
  const wallet = useWallet();
  const authenticate = useAuthentication();

  const [ethWallet, setEthWallet] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const addToList = async (ethereumWallet: string) => {
    const [messageId, signature] = (await authenticate()) as string[];

    // TODO change
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/allowlist/drop0`,
      {
        method: 'POST',
        headers: {
          Authorization: `Basic ${btoa(`${messageId}:${signature}`)}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ethereumWallet,
        }),
      }
    );

    if (response.ok) {
      // const addResponse = await response.json();
      // console.log(addResponse);
    } else {
      throw new Error('Failed to add to list');
    }
  };

  return (
    <div className="drop-0-allowlist-add">
      <h1>Drop 0 Holders Only</h1>
      <h3>Verify your Drop 0 ownership to mint Drop 2 for free.</h3>
      <div className="connect-container">
        {!wallet.connected ? (
          <div className="step">
            <h2 className="heading2">Connect your wallet</h2>
            <WalletMultiButton style={{ margin: '0 auto' }} />
          </div>
        ) : (
          <>
            {success ? (
              <div className="d-flex flex-column step">
                <h2 className="heading2">
                  Successfully confirmed Drop 0 ownership
                </h2>
                <p>Please allow 10 minutes for the transaction to complete.</p>
                <div className="join-list-container">
                  <Button
                    onClick={async () => {
                      window.location.replace(
                        'https://mint.endstate.io/drop2?ref=drop0'
                      );
                    }}
                    className="wallet-adapter-button"
                  >
                    MINT YOUR DROP 2
                  </Button>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column step">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <h2 className="heading2">
                    Enter in your Ethereum wallet public key
                  </h2>
                  <Form.Control
                    type="text"
                    placeholder="0x00000000000000000000000000000000"
                    value={ethWallet}
                    onChange={({ target: { value } }) => setEthWallet(value)}
                  />
                </Form.Group>
                <div className="join-list-container">
                  <Button
                    onClick={async () => {
                      setLoading(true);
                      setError('');
                      try {
                        await addToList(ethWallet);
                        setSuccess(true);
                      } catch (e) {
                        console.error(e);
                        setError(
                          'Failed to verify ownership. Please confirm address and try again.'
                        );
                      }
                      setLoading(false);
                    }}
                    className="wallet-adapter-button"
                  >
                    {loading ? 'LOADING...' : 'SUBMIT'}
                  </Button>
                  {error?.length > 0 && <p className="error-text">{error}</p>}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Drop0AllowListAdd;
