import './Drop2Reactor.scss';

import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Button } from 'react-bootstrap';
import useEthereumWeb3 from '../../hooks/useEthereumWeb3';
import { initDrop2 } from '../../blockchain/contracts';
import { IRedeemDataProps, RedeemForm } from '../../components/RedeemForm';
import { NFTData } from '../../types/NFTData';
import useRedemption from '../../hooks/useRedemption';
import {
  logErrorEvent,
  logEvent,
  setUserProperties,
} from '../../services/AnalyticsService';
import { formatAddress } from '../../blockchain/web3Utils';

const shoeName = 'Drop 2: Statecraft';

const packagingMap: {
  [key: string]: string;
} = {
  'fat laces': 'Sack Pack',
  'reflective laces': 'Sack Pack',
  'endstate seal': 'Backpack',
  'endstate dog tags': 'Backpack',
  'endstate medal of honor': 'Pelican Case',
  'fat laces, reflective laces, endstate seal, endstate dog tags, endstate medal of honor':
    'Pelican Case',
};

const Drop2Reactor: React.FC = () => {
  // when the user opens the webpage, they should have a button which allows them to connect their wallet
  // when the wallet is connected, we should show all NFTs that they have own and the connected wallet
  // each nft should have a redeem button
  // redeem button should launch them into the redeem flow
  // the redeem flow will ask for shoe size, shipping address (see old redeem flow)
  // submitting will require them to sign a transaction we will use for auth on the backend
  // send the auth and the redeem info to the API, if it's all good make the redeem

  const [isListLoading, setIsListLoading] = useState(false);
  const [nfts, setNfts] = useState<NFTData[] | undefined>(undefined);
  const [selectedNFT, setSelectedNFT] = useState<NFTData | undefined>(
    undefined
  );
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  const { requestConnectWallet, connectedWallet, ethereum } = useEthereumWeb3();
  const { redeemNFT } = useRedemption();

  useEffect(() => {
    if (!ethereum) {
      setWarning(
        'The Endstate Reactor requires a Web3 wallet extension to function'
      );
    }
  }, [ethereum]);

  useEffect(() => {
    (async () => {
      try {
        if (connectedWallet) {
          setIsListLoading(true);

          // get all the drop 2 NFTs
          const contract = await initDrop2();

          const balance = await contract.balanceOf(connectedWallet);

          const nftIds = await Promise.all(
            Array<number>(parseInt(balance.toString()))
              .fill(0)
              .map((_, i) => i)
              .map((i) => {
                return contract.tokenOfOwnerByIndex(connectedWallet, i);
              })
          );

          let redeemedCount = 0;

          let nftsWithData = await Promise.all(
            nftIds.map(async (id) => {
              const tokenURI = await contract.tokenURI(id);
              const result = await (await fetch(tokenURI)).json();
              console.log(result);
              if (result.endstate.isRedeemed) {
                redeemedCount++;
              }

              const colorway = result.attributes.find(
                (attr: any) => attr.trait_type === 'Colorway'
              ).value;

              return {
                nftMint: id.toString(),
                nftName: result.name,
                dropName: 'DROP 2 ' + colorway.toUpperCase(),
                shoeName,
                data: result,
                isRedeemed: result.endstate.isRedeemed,
              };
            })
          );

          setUserProperties({
            nft_count: nftIds.length,
            redeemed_count: redeemedCount,
          });
          logEvent('wallet_connected', {});

          setNfts(nftsWithData);
        }
      } catch (e: any) {
        console.error(e);
        logErrorEvent({ action: 'fetch_nfts', message: e.toString() });
        // Todo check if there is a reason why it may be failing
        setError('Could not get NFTs');
      }
      setIsListLoading(false);
    })();
  }, [connectedWallet]);

  const onRedeemNft = async (
    data: IRedeemDataProps
  ): Promise<{ transactionHash: string; gasUsed: number }> => {
    const embellishment = selectedNFT?.data.attributes.find(
      (attr: any) => attr.trait_type === 'Embellishment'
    ).value;

    const embellishmentProperty = {
      name: 'embellishment',
      value: embellishment,
    };
    const packaging = packagingMap[embellishment.toLowerCase()];
    const packagingProperty = {
      name: 'package',
      value: packaging,
    };

    if (embellishment && data.lineItems?.length) {
      data.lineItems[0].properties = [embellishmentProperty, packagingProperty];
    }

    return await redeemNFT(data, 'drop2');
  };

  return (
    <>
      <div className="drop2-reactor">
        <h1>Endstate Reactor</h1>
        <p>
          Redeem your NFT for a physical pair of Drop 2: Statecraft sneakers
        </p>
        <hr />
        {!connectedWallet ? (
          <>
            <p>Connect your wallet to get started</p>
            {error.length > 0 && (
              <div className="d-flex justify-content-center mt-4">
                <p className="reactor-error-text">{error}</p>
              </div>
            )}
            <div>
              {warning.length > 0 ? (
                <div className="d-flex justify-content-center mt-4">
                  <p className="reactor-warning-text">
                    {warning}
                    <br />
                    <a
                      href="https://metamask.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get started with MetaMask here
                    </a>
                  </p>
                </div>
              ) : (
                <>
                  {' '}
                  <Button
                    className="connect-wallet-button"
                    onClick={() => requestConnectWallet()}
                  >
                    CONNECT WALLET
                  </Button>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <h2 className="mb-4">
              Connected wallet: {formatAddress(connectedWallet)}
            </h2>
            {isListLoading ? (
              <>
                <p className="mb-2">Loading NFTs...</p>
                <TailSpin wrapperClass="loading" height={30} color="blue" />
              </>
            ) : (
              <>
                {nfts && nfts.length > 0 ? (
                  <div className="drop2-reactor-card-container">
                    {nfts.map((nft, i) => {
                      return (
                        <div className="drop2-reactor-card" key={i}>
                          <video
                            className="drop2-reactor-image"
                            src={nft.data.animation_url}
                            loop
                            autoPlay
                            muted
                          />
                          <p className="mt-2" key={i}>
                            {nft.nftName}
                          </p>
                          <div className="mt-4 mb-4">
                            {nft.isRedeemed ? (
                              <p className="drop2-reator-is-redeemed">
                                Redeemed
                              </p>
                            ) : (
                              <>
                                <Button
                                  className="drop2-reactor-redeem-button"
                                  onClick={() => {
                                    logEvent('redeem_button_pressed', {
                                      colorway: nft.data.attributes.find(
                                        (attr: any) =>
                                          attr.trait_type === 'Colorway'
                                      ).value,
                                      embellishment: nft.data.attributes.find(
                                        (attr: any) =>
                                          attr.trait_type === 'Embellishment'
                                      ).value,
                                    });
                                    setSelectedNFT(nft);
                                  }}
                                >
                                  REDEEM
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>You don't have any NFTs to redeem</p>
                )}
              </>
            )}
          </>
        )}
      </div>
      {selectedNFT && (
        <RedeemForm
          nft={selectedNFT}
          redeemNft={onRedeemNft}
          onClose={(success: boolean) => {
            if (success) {
              setNfts(
                nfts?.map((nft) =>
                  selectedNFT?.nftName === nft.nftName
                    ? { ...nft, isRedeemed: true }
                    : nft
                ) || []
              );
            }
            setSelectedNFT(undefined);
          }}
          logParams={{
            colorway: selectedNFT.data.attributes.find(
              (attr: any) => attr.trait_type === 'Colorway'
            ).value,
            embellishment: selectedNFT.data.attributes.find(
              (attr: any) => attr.trait_type === 'Embellishment'
            ).value,
          }}
        />
      )}
    </>
  );
};

export default Drop2Reactor;
