import Web3 from 'web3';

import { ethereum } from './metamask';

const mainnetUrl =
  'https://mainnet.infura.io/v3/62004c596e8d4c74809bce66427abd77';
const testnetUrl =
  'https://rinkeby.infura.io/v3/62004c596e8d4c74809bce66427abd77';
const localUrl = 'HTTP://127.0.0.1:7545';

const getNetUrl = (environment: string) => {
  switch (environment) {
    case 'development':
      return localUrl;
    case 'staging':
      return testnetUrl;
    case 'production':
      return mainnetUrl;
    default:
      return localUrl;
  }
};

// Is there an injected web3 instance?
let web3: Web3;
if (typeof ethereum?.web3 !== 'undefined') {
  // App.web3Provider = web3.currentProvider;
  web3 = new Web3(ethereum.web3.currentProvider);
} else {
  // If no injected web3 instance is detected, fallback to BC Node.
  web3 = new Web3(getNetUrl(process.env.REACT_APP_NODE_ENV || 'development'));
}

// @ts-ignore
const BN = web3.utils.BN;

const formatAddress = (address: string) => {
  return (
    address.substring(0, 6) + '...' + address.substring(address.length - 4)
  );
};

export { web3, BN, formatAddress };
