import "./Footer.scss";

import React from "react";

import logoText from "../../assets/images/endstate-logo-text.png";
import discordIcon from "../../assets/icons/discord.png";
import instagramIcon from "../../assets/icons/instagram.png";
import twitterIcon from "../../assets/icons/twitter.png";

const Footer: React.FC<any> = () => {
  return (
    <div className="footer">
      <div className="footer-main">
        <div className="footer-links">
          <div className="footer-links">
            <a className="link" href="https://www.endstate.io/drops/drop-0">
              DROPS
            </a>
            <a className="link" href="https://discord.gg/2bHG8uukxV">
              COMMUNITY
            </a>
          </div>
          <div className="footer-links">
            <a className="link" href="https://www.endstate.io/drops/drop-0">
              NEWS
            </a>
            <a className="link" href="https://www.endstate.io/about">
              ABOUT
            </a>
          </div>
        </div>
        <img className="footer-logo" src={logoText} alt="Endstate logo" />
        <div className="footer-socials">
          <a href="https://twitter.com/endstate">
            <img src={twitterIcon} alt="Twitter link" />
          </a>
          <a href="https://www.instagram.com/endstate/">
            <img src={instagramIcon} alt="Instagram link" />
          </a>
          <a href="https://discord.gg/2bHG8uukxV">
            <img src={discordIcon} alt="Discord link" />
          </a>
        </div>
      </div>
      <p className="footer-copyright">Copyright Endstate 2022</p>
    </div>
  );
};

export default Footer;
